import $ from 'jquery';
/*eslint-disable no-unused-vars*/
import Modernizr from 'vendor/modernizr';
/*eslint-enable no-unused-vars*/
import { getClassByName } from 'auth/controllers';

$(function() {
  let {
    controller,
    storeContext,
    locale
  } = $('body').data();

  let ControllerClass    = getClassByName(controller);
  let ControllerInstance = new ControllerClass({
    storeContext,
    locale
  });

  ControllerInstance.init();
});
